<template>
  <a :href="href">
    <slot></slot>
  </a>
</template>

<script>
import { appConfig } from "@/configs";

export default {
  name: "PublicLink",
  inject: ["profile"],
  props: {
    to: {
      type: String,
      required: true
    },
      auth: {
        type: Boolean, required: false
      }
  },
  data() {
    return {
      isAuth: false
    }
  },
  computed: {
    href() {
        if (this.auth) return appConfig.publicBaseUrl + this.to;
      return appConfig.publicBaseUrl + this.to + '?auth=' + (this.profile ? this.profile.paymentCode : 0);
    }
  },
}
</script>
