<template>
  <div class="sub-menu">
    <nav class="navbar navbar-expand navbar-light p-0">
      <ul class="navbar-nav sub-menu-nav">
        <li class="nav-item" :class="{ active: isActive('primaryMarket') }">
            <div class="d-flex">
          <router-link class="nav-link" :to="{ name: 'primaryMarket' }">Первичный рынок</router-link>
            <public-link class="navbar-brand mb-auto" to="/faq#primary-market" auth="false">
                <i class="bi-info-circle text-success icon-market-info"></i>
            </public-link>
            </div>

        </li>
        <li class="nav-item" :class="{ active: isActive('secondaryMarket') }">
            <div class="d-flex">
                <router-link class="nav-link" :to="{ name: 'secondaryMarket' }">Вторичный рынок</router-link>
                <public-link class="navbar-brand mb-auto" to="/faq#secondary-market" auth="false">
                    <i class="bi-info-circle text-success icon-market-info"></i>
                </public-link>
            </div>
        </li>
        <li v-if="guarantorFeature" class="nav-item" :class="{ active: isActive('buyBackOffers') }">
            <div class="d-flex">

          <router-link class="nav-link" :to="{ name: 'buyBackOffers' }">Вторичный рынок с Buy Back</router-link>
            <public-link class="navbar-brand mb-auto" to="/faq#secondary-market-buyback" auth="false">
                <i class="bi-info-circle text-success icon-market-info"></i>
            </public-link>
            </div>

        </li>
      </ul>
      <ul class="navbar-nav ml-auto mr-3">
        <li class="nav-item dropdown">
          <a class="nav-link"
             href="#list-filter"
             id="filter"
             role="button"
             data-toggle="collapse"
             aria-expanded="false"
          >
            Фильтры
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { appConfig } from "@/configs";
import PublicLink from "@/components/common/components/PublicLink.vue";

export default {
  name: "SubMenu",
    components: {PublicLink},
  data() {
    return {
      guarantorFeature: appConfig.guarantorFeature
    };
  },
  methods: {
    isActive(routeName) {
      const route = this.$router.resolve({ name: routeName });
      return this.$route.matched.some((item) => item.name === route.name || item.path.indexOf(route.path + '/') > -1);
    }
  }
};
</script>

<style lang="scss">
.sub-menu {
  .navbar {
    flex-wrap: wrap;
  }

  .sub-menu-nav {
    width: 100%;
  }

  #filter {
    color: #21e58a;
    font-size: 90%;
    font-weight: 500;
  }

  @media (min-width: 520px) {
    .navbar {
      flex-wrap: nowrap;
    }

    .sub-menu-nav {
      width: auto;
    }
  }

  @media (min-width: 1040px) {
    .navbar .navbar-nav .nav-item {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
  }

  @media (min-width: 1140px) {
    .navbar .navbar-nav .nav-item {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
}
</style>
