<template>
  <div class="invest">
    <div class="container-xl">
      <div v-if="withSubMenu" class="card mt-3 mb-3">
        <div class="card-body">
          <div class="h5">Доступные предложения</div>
        </div>
        <sub-menu class="mb-2"></sub-menu>
      </div>
    </div>
    <router-view :key="$route.fullPath" @dashboard-event="(et) => $emit('dashboardEvent', et)"></router-view>
  </div>
</template>

<script>
import SubMenu from "./components/SubMenu";

export default {
  name: "Invest",
  components: {
    SubMenu
  },
  computed: {
    withSubMenu() {
      return this.$route.meta.withSubMenu;
    }
  }
};
</script>
